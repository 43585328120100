import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero/PageHero'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'

const omOss = ({ data: { page } }) => {
  const heroImg = page.hero.bild
  const text = page.omOss.text
  return (
    <Layout>
      <Seo title={'Om oss'} description={text} />
      <PageHero title={'Om oss'} src={heroImg} />
      <Spacer />
      <div className="container">
        <div className="flex justify-center max-w-3xl mx-auto">
          <p>{text}</p>
        </div>
      </div>
      <Spacer />
    </Layout>
  )
}

export const query = graphql`
  query omOss {
    page: wpPage(title: { eq: "Om oss" }) {
      id
      omOss {
        text
      }
      hero {
        bild {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default omOss
